import { useCallback } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { ChangeScholarshipSelect } from './ChangeScholarshipSelect';
import { useScholarshipSelection } from '../utils/useScholarshipSelection';

interface ScholarshipSectionProps {
    cohortForAvailableScholarships?: CohortForAdminListsAttrs;
    initialScholarshipIds: string[];
    fieldName?: string;
    disabled?: boolean;
    onScholarshipChange?: (scholarshipIds: string[]) => void;
}

export const ScholarshipSection = ({
    cohortForAvailableScholarships,
    initialScholarshipIds = [],
    fieldName = 'scholarshipIds',
    disabled = false,
    onScholarshipChange,
}: ScholarshipSectionProps) => {
    const { setFieldValue } = useFormContext();
    const { scholarshipIds, availableScholarships } = useScholarshipSelection({
        cohortForAvailableScholarships,
        initialScholarshipIds,
        fieldName,
    });

    const handleAddScholarship = useCallback(() => {
        const newScholarshipIds = [...(scholarshipIds || []), undefined];
        setFieldValue(fieldName, newScholarshipIds);
        onScholarshipChange?.(newScholarshipIds.filter(Boolean) as string[]);
    }, [scholarshipIds, setFieldValue, fieldName, onScholarshipChange]);

    const handleRemoveScholarship = useCallback(
        (index: number) => {
            const newScholarshipIds = [...scholarshipIds];
            newScholarshipIds.splice(index, 1);
            setFieldValue(fieldName, newScholarshipIds);
            onScholarshipChange?.(newScholarshipIds.filter(Boolean) as string[]);
        },
        [scholarshipIds, setFieldValue, fieldName, onScholarshipChange],
    );

    return (
        <>
            <Button className="add-scholarship" startIcon={<Add />} onClick={handleAddScholarship} disabled={disabled}>
                Add Scholarship
            </Button>
            {scholarshipIds?.map((_id: string | undefined, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="scholarship-wrapper" key={index}>
                    <ChangeScholarshipSelect
                        name={`${fieldName}[${index}]`}
                        availableIds={availableScholarships.map(s => s.id)}
                        handleRemoveScholarship={() => handleRemoveScholarship(index)}
                    />
                </div>
            ))}
        </>
    );
};
