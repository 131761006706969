window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.chat = window.locales.am.chat || {};window.locales.am.chat.chat = window.locales.am.chat.chat || {};window.locales.am.chat.chat = {...window.locales.am.chat.chat, ...{
    "how_can_i_help": "እንዴት ልረዳ እችላለሁ?",
    "new_topic": "አዲስ ርዕስ",
    "submit_message": "መልእክት አስገባ",
    "hi_user": "ሰላም፣ {userName}! እንኳን ደህና መጣህ. ዛሬ እንዴት መርዳት እችላለሁ?",
    "learn_more": "ተጨማሪ እወቅ:",
    "rate_thumbs_up": "ይህንን መልስ በአውራ ጣት ወደ ላይ ደረጃ ይስጡት።",
    "rate_thumbs_down": "ይህንን መልስ በአውራ ጣት ወደ ታች ደረጃ ይስጡት።",
    "ai_welcome_message_stay_tuned": "ለማሻሻያዎች እና አዳዲስ መስተጋብር መንገዶችን ይጠብቁ!",
    "error": "ውይ። የሆነ ስህተት ተከስቷል. እባክዎ ዳግም ይሞክሩ.",
    "exam_in_progress_message": "ሰላም፣ {name}! {examTitle}ን እንደጨረሱ ቻታችን እንደገና ይገኛል። መልካም እድል!",
    "locked_welcome_modal": "የገቡት የ {institutionName} ተማሪዎች እና የቀድሞ ተማሪዎች የዕድሜ ልክ የመማር ጉዟቸውን ለመደገፍ የተሰራ የላቀ ቻትቦት AI አማካሪን ያገኛሉ።",
    "locked_welcome_modal_cta_message_signup_headline": "የሚቀጥለው የማመልከቻ የመጨረሻ ቀን፡-",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "አሁኑኑ ያመልክቱ",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "ቃለ መጠይቅዎን ያቅዱ፡",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "መርሐግብር",
    "locked_welcome_modal_cta_message_offered_admission_headline": "የምዝገባ የመጨረሻ ቀን፡-",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "ይመዝገቡ",
    "available_to_students_and_alumni": "ለተማሪዎች እና ለተማሪዎች ይገኛል።",
    "ai_advisor": "AI አማካሪ",
    "locked_unavailable": "የ AI አማካሪ በአሁኑ ጊዜ አይገኝም፣ ለተፈጠረው ችግር ይቅርታ እንጠይቃለን።",
    "ai_welcome_message_notice": "ጥያቄ አለህ? AI አማካሪ ለመርዳት ዝግጁ ነው። የውይይት <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">መልእክት ታሪክ</a> የወደፊት የቦት መስተጋብርን ለማሻሻል ጥቅም ላይ ሊውል እንደሚችል ልብ ይበሉ። ",
    "ai_welcome_message_notice_no_link": "ጥያቄ አለህ? AI አማካሪ ለመርዳት ዝግጁ ነው። የውይይት መልእክት ታሪክ የወደፊት የቦት መስተጋብርን ለማሻሻል ጥቅም ላይ ሊውል እንደሚችል ልብ ይበሉ። ",
    "lesson_player_welcome_message_notice": "ስለ ኮርስ ሥራ ጥያቄ አለዎት? አጋዥ ስልጠና እና ተጨማሪ ልምምድ ለማግኘት ከ AI አማካሪ ጋር ይወያዩ። የውይይት <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">መልእክት ታሪክ</a> የወደፊት የቦት መስተጋብርን ለማሻሻል ጥቅም ላይ ሊውል እንደሚችል ልብ ይበሉ።",
    "lesson_player_welcome_message_notice_no_link": "ስለ ኮርስ ሥራ ጥያቄ አለዎት? አጋዥ ስልጠና እና ተጨማሪ ልምምድ ለማግኘት ከ AI አማካሪ ጋር ይወያዩ። የውይይት መልእክት ታሪክ የወደፊት የቦት መስተጋብርን ለማሻሻል ጥቅም ላይ ሊውል እንደሚችል ልብ ይበሉ።",
    "ai_advisor_can_make_mistakes": "AI አማካሪ ስህተት ሊሠራ ይችላል. አስፈላጊ መረጃን ለማጣራት ያስቡበት.",
    "cancel_response": "ሰርዝ",
    "ai_tutor_can_make_mistakes": "AI Tutor ስህተት ሊሠራ ይችላል። ጠቃሚ መረጃን ለማጣራት ያስቡበት.",
    "message_ai_tutor": "መልዕክት {AI} አስተማሪ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.chat = window.locales.ar.chat || {};window.locales.ar.chat.chat = window.locales.ar.chat.chat || {};window.locales.ar.chat.chat = {...window.locales.ar.chat.chat, ...{
    "how_can_i_help": "كيف يمكنني أن أقدم المساعدة؟",
    "new_topic": "موضوع جديد",
    "submit_message": "تقديم رسالة",
    "hi_user": "مرحبًا {userName}! مرحبًا بعودتك. كيف يمكنني المساعدة اليوم؟",
    "learn_more": "يتعلم أكثر:",
    "rate_thumbs_up": "قيم هذه الإجابة بإبهام لأعلى.",
    "rate_thumbs_down": "قيم هذه الإجابة بإعجاب.",
    "ai_welcome_message_stay_tuned": "ترقبوا الترقيات والطرق الجديدة للتفاعل!",
    "error": "أُووبس. هناك خطأ ما. حاول مرة اخرى.",
    "exam_in_progress_message": "مرحبًا {name}! ستكون محادثتنا متاحة مرة أخرى بمجرد إكمال {examTitle}. حظا سعيدا!",
    "locked_welcome_modal": "يتمتع الطلاب والخريجون المقبولون في {institutionName} بإمكانية الوصول إلى AI Advisor، وهو روبوت دردشة متقدم تم تصميمه لدعم رحلة التعلم مدى الحياة.",
    "locked_welcome_modal_cta_message_signup_headline": "الموعد النهائي لتقديم الطلبات التالي:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "قدم الآن",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "حدد موعد المقابلة الخاصة بك:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "جدول",
    "locked_welcome_modal_cta_message_offered_admission_headline": "آخر موعد للتسجيل:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "يسجل",
    "available_to_students_and_alumni": "متاح للطلاب والخريجين",
    "ai_advisor": "مستشار الذكاء الاصطناعي",
    "locked_unavailable": "مستشار الذكاء الاصطناعي غير متوفر حاليًا، نأسف على الإزعاج.",
    "ai_welcome_message_notice": "لدي سؤال؟ مستشار الذكاء الاصطناعي على استعداد للمساعدة. لاحظ أنه يمكن استخدام <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">سجل رسائل</a> المحادثة لتحسين تفاعلات الروبوت المستقبلية. ",
    "ai_welcome_message_notice_no_link": "لدي سؤال؟ مستشار الذكاء الاصطناعي على استعداد للمساعدة. لاحظ أنه يمكن استخدام سجل رسائل المحادثة لتحسين تفاعلات الروبوت المستقبلية. ",
    "lesson_player_welcome_message_notice": "هل لديك سؤال حول الدورات الدراسية؟ قم بالدردشة مع AI Advisor للحصول على مساعدة تعليمية وممارسة إضافية. لاحظ أنه يمكن استخدام <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">سجل رسائل</a> المحادثة لتحسين تفاعلات الروبوت المستقبلية.",
    "lesson_player_welcome_message_notice_no_link": "هل لديك سؤال حول الدورات الدراسية؟ قم بالدردشة مع AI Advisor للحصول على مساعدة تعليمية وممارسة إضافية. لاحظ أنه يمكن استخدام سجل رسائل المحادثة لتحسين تفاعلات الروبوت المستقبلية.",
    "ai_advisor_can_make_mistakes": "يمكن أن يرتكب مستشار الذكاء الاصطناعي أخطاء. فكر في التحقق من المعلومات المهمة.",
    "cancel_response": "يلغي",
    "ai_tutor_can_make_mistakes": "يمكن أن يرتكب مدرس الذكاء الاصطناعي الأخطاء. فكر في التحقق من المعلومات المهمة.",
    "message_ai_tutor": "رسالة {AI} مدرس"
}
};window.locales.en = window.locales.en || {};window.locales.en.chat = window.locales.en.chat || {};window.locales.en.chat.chat = window.locales.en.chat.chat || {};window.locales.en.chat.chat = {...window.locales.en.chat.chat, ...{
    "how_can_i_help": "How can I help?",
    "message_ai_tutor": "Message {AI} Tutor",
    "new_topic": "New Topic",
    "submit_message": "Submit Message",
    "cancel_response": "Cancel",
    "ai_welcome_message_notice": "Have a question? AI Advisor is ready to help. Note that conversation <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">message history</a> may be used to improve future bot interactions. ",
    "ai_welcome_message_notice_no_link": "Have a question? AI Advisor is ready to help. Note that conversation message history may be used to improve future bot interactions. ",
    "lesson_player_welcome_message_notice": "Have a question about coursework? Chat with AI Advisor for tutoring help and extra practice. Note that conversation <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">message history</a> may be used to improve future bot interactions.",
    "lesson_player_welcome_message_notice_no_link": "Have a question about coursework? Chat with AI Advisor for tutoring help and extra practice. Note that conversation message history may be used to improve future bot interactions.",

    "ai_welcome_message_stay_tuned": "Stay tuned for upgrades and new ways to interact!",
    "learn_more": "Learn More:",
    "rate_thumbs_up": "Rate this answer with thumbs up.",
    "rate_thumbs_down": "Rate this answer with thumbs down.",
    "error": "Oops. Something went wrong. Please try again.",
    "exam_in_progress_message": "Hi, {name}! Our chat will be available again once you've completed the {examTitle}. Best of luck!",
    "locked_welcome_modal": "Admitted {institutionName} students and alumni gain access to AI Advisor, an advanced chatbot built to support their lifelong learning journey.",
    "locked_welcome_modal_cta_message_signup_headline": "Next application deadline:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "Apply Now",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "Schedule your interview:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "Schedule",
    "locked_welcome_modal_cta_message_offered_admission_headline": "Registration deadline:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "Register",
    "locked_unavailable": "The AI Advisor is currently unavailable, we are sorry for the inconvenience.",
    "available_to_students_and_alumni": "Available to students and alumni",
    "ai_advisor": "AI Advisor",
    "ai_advisor_can_make_mistakes": "AI Advisor can make mistakes. Consider checking important information.",
    "ai_tutor_can_make_mistakes": "AI Tutor can make mistakes. Consider checking important information."
}
};window.locales.es = window.locales.es || {};window.locales.es.chat = window.locales.es.chat || {};window.locales.es.chat.chat = window.locales.es.chat.chat || {};window.locales.es.chat.chat = {...window.locales.es.chat.chat, ...{
    "how_can_i_help": "¿Cómo puedo ayudar?",
    "new_topic": "Nuevo tema",
    "submit_message": "Enviar mensaje",
    "hi_user": "¡Hola, {userName}! Bienvenido de nuevo. ¿Cómo puedo ayudar hoy?",
    "learn_more": "Aprende más:",
    "rate_thumbs_up": "Califica esta respuesta con pulgares arriba.",
    "rate_thumbs_down": "Califica esta respuesta con los pulgares hacia abajo.",
    "ai_welcome_message_stay_tuned": "¡Estén atentos a las actualizaciones y nuevas formas de interactuar!",
    "error": "Ups. Algo salió mal. Inténtalo de nuevo.",
    "exam_in_progress_message": "Hola, {name}! Nuestro chat volverá a estar disponible una vez que haya completado el {examTitle}. ¡Toda la suerte!",
    "locked_welcome_modal": "Los estudiantes y ex alumnos admitidos de {institutionName} obtienen acceso a AI Advisor, un chatbot avanzado creado para respaldar su viaje de aprendizaje permanente.",
    "locked_welcome_modal_cta_message_signup_headline": "Próxima fecha límite de solicitud:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "Aplica ya",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "Agenda tu entrevista:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "Cronograma",
    "locked_welcome_modal_cta_message_offered_admission_headline": "Fecha limite de registro:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "Registro",
    "available_to_students_and_alumni": "Disponible para estudiantes y exalumnos",
    "ai_advisor": "Asesor de IA",
    "locked_unavailable": "El Asesor de IA no está disponible actualmente, lamentamos las molestias.",
    "ai_welcome_message_notice": "¿Tengo una pregunta? AI Advisor está listo para ayudar. Tenga en cuenta que <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">el historial de mensajes</a> de conversación se puede utilizar para mejorar futuras interacciones con el bot. ",
    "ai_welcome_message_notice_no_link": "¿Tengo una pregunta? AI Advisor está listo para ayudar. Tenga en cuenta que el historial de mensajes de conversación se puede utilizar para mejorar futuras interacciones con el bot. ",
    "lesson_player_welcome_message_notice": "¿Tiene alguna pregunta sobre los cursos? Chatea con AI Advisor para obtener ayuda de tutoría y práctica adicional. Tenga en cuenta que <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">el historial de mensajes</a> de conversación se puede utilizar para mejorar futuras interacciones con el bot.",
    "lesson_player_welcome_message_notice_no_link": "¿Tiene alguna pregunta sobre los cursos? Chatea con AI Advisor para obtener ayuda de tutoría y práctica adicional. Tenga en cuenta que el historial de mensajes de conversación se puede utilizar para mejorar futuras interacciones con el bot.",
    "ai_advisor_can_make_mistakes": "AI Advisor puede cometer errores. Considere verificar información importante.",
    "cancel_response": "Cancelar",
    "ai_tutor_can_make_mistakes": "AI Tutor puede cometer errores. Considere verificar información importante.",
    "message_ai_tutor": "Mensaje {AI} Tutor"
}
};window.locales.it = window.locales.it || {};window.locales.it.chat = window.locales.it.chat || {};window.locales.it.chat.chat = window.locales.it.chat.chat || {};window.locales.it.chat.chat = {...window.locales.it.chat.chat, ...{
    "how_can_i_help": "Come posso aiutare?",
    "new_topic": "Nuovo argomento",
    "submit_message": "Invia messaggio",
    "hi_user": "Ciao, {userName}! Bentornato. Come posso aiutare oggi?",
    "learn_more": "Saperne di più:",
    "rate_thumbs_up": "Vota questa risposta con pollice in su.",
    "rate_thumbs_down": "Valuta questa risposta con il pollice in giù.",
    "ai_welcome_message_stay_tuned": "Resta sintonizzato per aggiornamenti e nuovi modi di interagire!",
    "error": "Ops. Qualcosa è andato storto. Per favore riprova.",
    "exam_in_progress_message": "Ciao, {name}! La nostra chat sarà di nuovo disponibile una volta completato il {examTitle}. Buona fortuna!",
    "locked_welcome_modal": "Gli studenti e gli ex studenti ammessi di {institutionName} ottengono l'accesso ad AI Advisor, un chatbot avanzato creato per supportare il loro percorso di apprendimento permanente.",
    "locked_welcome_modal_cta_message_signup_headline": "Prossima scadenza per le domande:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "Applica ora",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "Pianifica il tuo colloquio:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "Programma",
    "locked_welcome_modal_cta_message_offered_admission_headline": "Termine per la registrazione:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "Registrati",
    "available_to_students_and_alumni": "Disponibile per studenti ed ex studenti",
    "ai_advisor": "Consulente dell'intelligenza artificiale",
    "locked_unavailable": "L'AI Advisor non è al momento disponibile, ci scusiamo per l'inconveniente.",
    "ai_welcome_message_notice": "Hai una domanda? AI Advisor è pronto ad aiutarti. Tieni presente che <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">la cronologia dei messaggi di</a> conversazione può essere utilizzata per migliorare le future interazioni con i bot. ",
    "ai_welcome_message_notice_no_link": "Hai una domanda? AI Advisor è pronto ad aiutarti. Tieni presente che la cronologia dei messaggi di conversazione può essere utilizzata per migliorare le future interazioni con i bot. ",
    "lesson_player_welcome_message_notice": "Hai una domanda sui corsi? Chatta con AI Advisor per aiuto tutoraggio e pratica extra. Tieni presente che <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">la cronologia dei messaggi di</a> conversazione può essere utilizzata per migliorare le future interazioni dei bot.",
    "lesson_player_welcome_message_notice_no_link": "Hai una domanda sui corsi? Chatta con AI Advisor per aiuto tutoraggio e pratica extra. Tieni presente che la cronologia dei messaggi di conversazione può essere utilizzata per migliorare le future interazioni con i bot.",
    "ai_advisor_can_make_mistakes": "L'AI Advisor può commettere errori. Considera la possibilità di controllare le informazioni importanti.",
    "cancel_response": "Annulla",
    "ai_tutor_can_make_mistakes": "AI Tutor può commettere errori. Considera la possibilità di controllare le informazioni importanti.",
    "message_ai_tutor": "Messaggio {AI} Tutor"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.chat = window.locales.zh.chat || {};window.locales.zh.chat.chat = window.locales.zh.chat.chat || {};window.locales.zh.chat.chat = {...window.locales.zh.chat.chat, ...{
    "how_can_i_help": "我能提供什么帮助？",
    "new_topic": "新话题",
    "submit_message": "提交留言",
    "hi_user": "嗨，{userName}！欢迎回来。今天我能提供什么帮助？",
    "learn_more": "了解更多：",
    "rate_thumbs_up": "竖起大拇指评价这个答案。",
    "rate_thumbs_down": "用大拇指向下评价这个答案。",
    "ai_welcome_message_stay_tuned": "请继续关注升级和新的互动方式！",
    "error": "哎呀。出了些问题。请再试一次。",
    "exam_in_progress_message": "嗨，{name}！完成 {examTitle} 后，我们的聊天将再次可用。祝你好运！",
    "locked_welcome_modal": "被录取的{institutionName}学生和校友可以使用 AI Advisor，这是一种高级聊天机器人，旨在支持他们的终身学习之旅。",
    "locked_welcome_modal_cta_message_signup_headline": "下次申请截止日期：",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "现在申请",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "安排面试：",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "日程",
    "locked_welcome_modal_cta_message_offered_admission_headline": "报名截止日期：",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "登记",
    "available_to_students_and_alumni": "可供学生和校友使用",
    "ai_advisor": "人工智能顾问",
    "locked_unavailable": "AI Advisor 目前无法使用，对于给您带来的不便，我们深表歉意。",
    "ai_welcome_message_notice": "有一个问题？ AI Advisor 已准备好提供帮助。请注意，对话<a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">消息历史</a>记录可用于改进未来的机器人交互。 ",
    "ai_welcome_message_notice_no_link": "有一个问题？ AI Advisor 已准备好提供帮助。请注意，对话消息历史记录可用于改进未来的机器人交互。 ",
    "lesson_player_welcome_message_notice": "对课程作业有疑问吗？与 AI Advisor 聊天以获得辅导帮助和额外练习。请注意，对话<a class=\"text-white underline\" href=\"{faqUrl}\", target=\"_blank\">消息历史</a>记录可用于改进未来的机器人交互。",
    "lesson_player_welcome_message_notice_no_link": "对课程作业有疑问吗？与 AI Advisor 聊天以获得辅导帮助和额外练习。请注意，对话消息历史记录可用于改进未来的机器人交互。",
    "ai_advisor_can_make_mistakes": "人工智能顾问可能会犯错误。考虑检查重要信息。",
    "cancel_response": "取消",
    "ai_tutor_can_make_mistakes": "AI 导师可能会犯错误。请考虑检查重要信息。",
    "message_ai_tutor": "留言{AI}导师"
}
};