import { useEffect, useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { type PaymentSituation } from 'PaymentSituation';
import { type ProgramInclusion } from 'ProgramInclusion';
import { isFullScholarship } from 'Scholarship';
import { isOneTimePlan } from 'TuitionPlan';
import { PaymentInformationSection } from '../../HelperComponents/PaymentInformationSection';
import usePaymentSituation from '../../utils/usePaymentSituation';
import { useGetUserInAdmin } from '../../redux';
import PaymentSelect from '../../HelperComponents/PaymentSelect';
import { HandlePaymentOptionVal } from '../../HelperComponents/PaymentSelect.types';
import { showDatePicker } from '../../HelperComponents/PaymentSelectHelper';
import { useTuitionPlans } from '../AdmissionOffer/RegisterStudentHelpers';
import TuitionBenefitAdministratorSelect from '../../HelperComponents/TuitionBenefitAdministratorSelect';
import TuitionPlanSelect from '../../HelperComponents/TuitionPlanSelect';
import AbstractUserManagementAction from '../AbstractUserManagementAction';
import {
    type FormFieldProps,
    type UserManagementActionAttrs,
    InclusionActionType,
    UserManagementActionNamespace,
} from '../UserManagementAction.types';
import './ChangeProgram.scss';

type FormFields = {
    tuitionPlanId: string | null;
    tuitionBenefitAdministrator: string | null;
    isPaymentSituationChanged: boolean;
    paymentGracePeriodEndAt: number | null;
    createSubscription: boolean;
    handlePaymentOption: HandlePaymentOptionVal | null;
};

type ExecuteActionParams = {
    tuitionPlanId: string | null;
    paymentGracePeriodEndAt: number | null | undefined;
    tuitionBenefitAdministrator: string | null;
    createSubscription: boolean;
};
interface ChangeRegistrationAttrs extends UserManagementActionAttrs {
    selectablePaymentSituations: PaymentSituation[];
    isInFirst30DaysAfterCohortStart: boolean;
}

class ChangeRegistration extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeRegistration;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    get description() {
        return (
            <>
                <p>Use when a student would like to change their selection on the registration screen.</p>
                {!this.isInFirst30DaysAfterCohortStart && (
                    <p style={{ color: 'red' }}>
                        This student is past the 30 day limit to change their plan, use only if this exception has been
                        approved.
                    </p>
                )}
            </>
        );
    }

    static formValidationSchema = Yup.object().shape({
        tuitionPlanId: Yup.string().required(),
        tuitionBenefitAdministrator: Yup.string().nullable(),
        handlePaymentOption: Yup.string().when('isPaymentSituationChanged', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
        paymentGracePeriodEndAt: Yup.number().when('handlePaymentOption', {
            is: (val: HandlePaymentOptionVal) => showDatePicker(val),
            then: Yup.number().required(),
            otherwise: Yup.number().nullable().defined(),
        }),
        createSubscription: Yup.boolean().required(),
    });

    selectablePaymentSituations: PaymentSituation[];
    isInFirst30DaysAfterCohortStart: boolean;

    constructor(attrs: ChangeRegistrationAttrs) {
        super(attrs);
        this.selectablePaymentSituations = attrs.selectablePaymentSituations;
        this.isInFirst30DaysAfterCohortStart = attrs.isInFirst30DaysAfterCohortStart;
    }

    formatFormValues({
        tuitionPlanId,
        tuitionBenefitAdministrator,
        paymentGracePeriodEndAt,
        createSubscription,
    }: FormFields): ExecuteActionParams {
        return {
            paymentGracePeriodEndAt: paymentGracePeriodEndAt
                ? moment(paymentGracePeriodEndAt).valueOf() / 1000
                : paymentGracePeriodEndAt === null
                ? null
                : undefined,
            tuitionPlanId,
            tuitionBenefitAdministrator: tuitionBenefitAdministrator || null,
            createSubscription,
        };
    }

    // eslint-disable-next-line max-lines-per-function
    static FormFields = ({
        userId,
        record,
        action: { selectablePaymentSituations },
    }: FormFieldProps<ProgramInclusion, ChangeRegistration>) => {
        const { cohortId, cumulativeScholarship, tuitionPlanId: oldTuitionPlanId, tuitionContractId } = record;

        const { user } = useGetUserInAdmin(userId);
        const { watch, formState, reset, setFieldValue } = useFormContext<FormFields>();

        const tuitionPlanId = watch('tuitionPlanId');
        const isPaymentSituationChanged = watch('isPaymentSituationChanged');
        const handlePaymentOption = watch('handlePaymentOption');
        const createSubscription = watch('createSubscription');

        const tuitionPlans = useTuitionPlans(selectablePaymentSituations, cumulativeScholarship);

        const originalPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            oldTuitionPlanId,
            cumulativeScholarship,
        );

        const newPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            tuitionPlanId,
            cumulativeScholarship,
        );

        const isOneTimePlanSelected = isOneTimePlan(tuitionPlanId, tuitionPlans);

        const tuitionContract = useMemo(
            () => user?.tuitionContracts.find(tc => tc.id === tuitionContractId),
            [user, tuitionContractId],
        );

        useEffect(() => {
            reset({
                tuitionPlanId: oldTuitionPlanId,
            });
        }, [oldTuitionPlanId, reset]);

        useEffect(() => {
            setFieldValue('tuitionBenefitAdministrator', tuitionContract?.tuitionBenefitAdministrator || '');
        }, [tuitionContract, setFieldValue]);

        useEffect(() => {
            setFieldValue('isPaymentSituationChanged', tuitionPlanId !== oldTuitionPlanId);
        }, [tuitionPlanId, oldTuitionPlanId, setFieldValue]);

        useEffect(() => {
            setFieldValue('createSubscription', handlePaymentOption === HandlePaymentOptionVal.SubscriptionAuto);
        }, [handlePaymentOption, setFieldValue]);

        return (
            <>
                <TuitionPlanSelect
                    name="tuitionPlanId"
                    tuitionPlans={tuitionPlans}
                    disabled={formState.isSubmitting || tuitionPlans.length === 0}
                />

                {originalPaymentSituation && (
                    <PaymentInformationSection
                        paymentSituations={{ original: originalPaymentSituation, new: newPaymentSituation }}
                        showNewPayment={isPaymentSituationChanged}
                        cohortId={cohortId}
                    />
                )}

                <TuitionBenefitAdministratorSelect
                    name="tuitionBenefitAdministrator"
                    disabled={formState.isSubmitting}
                />

                {isPaymentSituationChanged && newPaymentSituation && (
                    <PaymentSelect
                        disabled={!newPaymentSituation || isFullScholarship(cumulativeScholarship)}
                        isFullScholarship={isFullScholarship(cumulativeScholarship)}
                        isOneTimePlan={isOneTimePlanSelected}
                        actionType={ChangeRegistration.actionType}
                    />
                )}

                <p>
                    Any changes to the user&apos;s payment situation will need to be done manually. This includes
                    {!createSubscription && ' creating a new subscription /'} invoicing the user through Stripe or
                    recording wire transfers.
                </p>
            </>
        );
    };
}

export default ChangeRegistration;
