import { useEffect } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { useOfferableScholarships } from '../redux';

interface UseScholarshipSelectionProps {
    cohortForAvailableScholarships?: CohortForAdminListsAttrs;
    initialScholarshipIds: string[];
    fieldName?: string;
}

export const useScholarshipSelection = ({
    cohortForAvailableScholarships,
    initialScholarshipIds = [],
    fieldName = 'scholarshipIds',
}: UseScholarshipSelectionProps) => {
    const { watch, setFieldValue } = useFormContext();
    const scholarshipIds = watch(fieldName) as (string | undefined)[];

    useEffect(() => {
        if (!Array.isArray(scholarshipIds)) {
            setFieldValue(fieldName, initialScholarshipIds);
        }
    }, [initialScholarshipIds, scholarshipIds, setFieldValue, fieldName]);

    const { availableScholarships } = useOfferableScholarships(cohortForAvailableScholarships?.availableScholarshipIds);

    const newCumulativeLevel = availableScholarships
        .filter(s => scholarshipIds?.filter(Boolean).includes(s.id))
        .reduce((acc, s) => acc + (s.level || 0), 0);
    const cumulativeScholarship = availableScholarships.find(s => s.level === newCumulativeLevel) || null;

    const hasSelectedScholarships = scholarshipIds?.length > 0 && scholarshipIds.some(id => id !== undefined);

    return {
        scholarshipIds,
        availableScholarships,
        cumulativeScholarship,
        hasSelectedScholarships,
    };
};
