window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.aiAdvisorTooltip = window.locales.am.aiAdvisorTooltip || {};window.locales.am.aiAdvisorTooltip.aiAdvisorTooltip = window.locales.am.aiAdvisorTooltip.aiAdvisorTooltip || {};window.locales.am.aiAdvisorTooltip.aiAdvisorTooltip = {...window.locales.am.aiAdvisorTooltip.aiAdvisorTooltip, ...{
    "curious": "የማወቅ ጉጉት ይሰማዎታል?",
    "hereToHelp": "AI ሞግዚት ለመርዳት እዚህ አለ! ውስብስብ ሀሳቦችን ያብራራል, ግንዛቤዎን ያበለጽጋል እና ወደ ትክክለኛው መፍትሄ ይመራዎታል."
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.aiAdvisorTooltip = window.locales.ar.aiAdvisorTooltip || {};window.locales.ar.aiAdvisorTooltip.aiAdvisorTooltip = window.locales.ar.aiAdvisorTooltip.aiAdvisorTooltip || {};window.locales.ar.aiAdvisorTooltip.aiAdvisorTooltip = {...window.locales.ar.aiAdvisorTooltip.aiAdvisorTooltip, ...{
    "curious": "هل تشعر بالفضول؟",
    "hereToHelp": "تطبيق AI Tutor هنا لمساعدتك! فهو يوضح الأفكار المعقدة ويثري فهمك ويرشدك إلى الحل الصحيح."
}
};window.locales.en = window.locales.en || {};window.locales.en.aiAdvisorTooltip = window.locales.en.aiAdvisorTooltip || {};window.locales.en.aiAdvisorTooltip.aiAdvisorTooltip = window.locales.en.aiAdvisorTooltip.aiAdvisorTooltip || {};window.locales.en.aiAdvisorTooltip.aiAdvisorTooltip = {...window.locales.en.aiAdvisorTooltip.aiAdvisorTooltip, ...{
    "curious": "Feeling Curious?",
    "hereToHelp": "AI Tutor is here to help! It clarifies complex ideas, enriches your understanding, and guides you to the right solution."
}
};window.locales.es = window.locales.es || {};window.locales.es.aiAdvisorTooltip = window.locales.es.aiAdvisorTooltip || {};window.locales.es.aiAdvisorTooltip.aiAdvisorTooltip = window.locales.es.aiAdvisorTooltip.aiAdvisorTooltip || {};window.locales.es.aiAdvisorTooltip.aiAdvisorTooltip = {...window.locales.es.aiAdvisorTooltip.aiAdvisorTooltip, ...{
    "curious": "¿Sientes curiosidad?",
    "hereToHelp": "AI Tutor está aquí para ayudarte. Aclara ideas complejas, enriquece tu comprensión y te guía hacia la solución adecuada."
}
};window.locales.it = window.locales.it || {};window.locales.it.aiAdvisorTooltip = window.locales.it.aiAdvisorTooltip || {};window.locales.it.aiAdvisorTooltip.aiAdvisorTooltip = window.locales.it.aiAdvisorTooltip.aiAdvisorTooltip || {};window.locales.it.aiAdvisorTooltip.aiAdvisorTooltip = {...window.locales.it.aiAdvisorTooltip.aiAdvisorTooltip, ...{
    "curious": "Ti senti curioso?",
    "hereToHelp": "AI Tutor è qui per aiutarti! Chiarisce idee complesse, arricchisce la tua comprensione e ti guida verso la soluzione giusta."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.aiAdvisorTooltip = window.locales.zh.aiAdvisorTooltip || {};window.locales.zh.aiAdvisorTooltip.aiAdvisorTooltip = window.locales.zh.aiAdvisorTooltip.aiAdvisorTooltip || {};window.locales.zh.aiAdvisorTooltip.aiAdvisorTooltip = {...window.locales.zh.aiAdvisorTooltip.aiAdvisorTooltip, ...{
    "curious": "感到好奇吗？",
    "hereToHelp": "AI Tutor 来帮你了！它可以澄清复杂的想法，丰富你的理解，并指导你找到正确的解决方案。"
}
};