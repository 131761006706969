import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { MenuItem, TextField, type SelectChangeEvent, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import {
    NO_SCHOLARSHIP,
    NO_SCHOLARSHIP_LEVEL,
    FULL_SCHOLARSHIP_LEVEL,
    FULL_SCHOLARSHIP,
    type Scholarship,
} from 'Scholarship';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { scholarshipsByLevel } from '../utils';
import { useOfferableScholarships } from '../redux';

interface ChangeScholarshipSelectProps {
    name: string;
    availableIds?: string[];

    // By default, this component selects a scholarship id. If selectLevel is true,
    // then it will instead select a scholarship level. This only works when there is
    // only one scholarship available for each level (see UpdateExecEdEligibilityBundle)
    selectLevel?: boolean;

    handleRemoveScholarship: () => void;
}

const optionLabel = (translateFn: (val: string) => string, { label, amount }: Scholarship) => {
    let name = 'Base Scholarship';

    if (label) {
        name = translateFn(`scholarship_labels.${label}`);
    }

    return `${name}${amount ? ` - $${amount}` : ''}`;
};

// eslint-disable-next-line max-lines-per-function
export const ChangeScholarshipSelect = ({
    name,
    availableIds,
    handleRemoveScholarship,
    selectLevel = false,
}: ChangeScholarshipSelectProps) => {
    const { formState, watch, setFieldValue } = useFormContext();
    const fieldValue = watch(name); // fieldValue can be either an id or a level depending on selectLevel
    const [level, setLevel] = useState<number | string>('');
    const [scholarshipId, setScholarshipId] = useState<string>('');
    const { t } = useTranslation('back_royal');

    const { offerableScholarships, availableScholarships, scholarshipsLoading } =
        useOfferableScholarships(availableIds);

    const scholarshipsMap = scholarshipsByLevel(availableScholarships);
    const scholarshipsForSelectedLevel = useMemo(
        () => (level ? scholarshipsMap[level] || [] : []),
        [level, scholarshipsMap],
    );

    // Set initial values when scholarships load
    useEffect(() => {
        if (!offerableScholarships?.length) return;

        if (selectLevel && fieldValue !== level) setLevel(fieldValue || '');
        if (!selectLevel && fieldValue !== scholarshipId) {
            const selectedLevel = offerableScholarships.find(ss => ss.id === fieldValue)?.level;
            if (selectedLevel) {
                setLevel(selectedLevel);
                setScholarshipId(fieldValue || '');
            }
        }
    }, [offerableScholarships, scholarshipId, level, fieldValue, selectLevel]);

    // Automatically select the scholarship if there is only one available for the selected level
    useEffect(() => {
        if (scholarshipsForSelectedLevel.length === 1) {
            const scholarship = scholarshipsForSelectedLevel[0];
            const newValue = selectLevel ? scholarship.level : scholarship.id;
            setScholarshipId(scholarship.id);
            if (fieldValue !== newValue) {
                setFieldValue(name, newValue);
            }
        }
    }, [level, scholarshipsForSelectedLevel, setFieldValue, fieldValue, selectLevel, name]);

    const handleLevelChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
        setLevel(value as number);
        setScholarshipId('');
        setFieldValue(name, undefined);
    };

    const handleScholarshipChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
        const selectedVal = selectLevel ? (value as number) : (value as string);
        const scholarship = scholarshipsForSelectedLevel.find(s =>
            selectLevel ? s.level === selectedVal : s.id === selectedVal,
        );

        if (scholarship) setScholarshipId(scholarship.id);
        setFieldValue(name, selectLevel ? scholarship?.level : scholarship?.id);
    };

    const internalHandleRemoveScholarship = useCallback(() => {
        setLevel('');
        setScholarshipId('');
        handleRemoveScholarship();
    }, [setLevel, setScholarshipId, handleRemoveScholarship]);

    return scholarshipsLoading ? (
        <FrontRoyalSpinner />
    ) : (
        <>
            <TextField
                select
                variant="outlined"
                label="Level"
                SelectProps={{
                    value: level,
                    fullWidth: true,
                    disabled: formState.isSubmitting || !Object.keys(scholarshipsMap).length,
                    onChange: handleLevelChange,
                }}
            >
                {Object.keys(scholarshipsMap)
                    .map(levelString => ({
                        value: levelString,
                        label:
                            Number(levelString) === NO_SCHOLARSHIP_LEVEL
                                ? NO_SCHOLARSHIP
                                : Number(levelString) === FULL_SCHOLARSHIP_LEVEL
                                ? FULL_SCHOLARSHIP
                                : levelString,
                        sortKey:
                            Number(levelString) === NO_SCHOLARSHIP_LEVEL
                                ? Number.MIN_VALUE
                                : Number(levelString) === FULL_SCHOLARSHIP_LEVEL
                                ? Number.MAX_VALUE
                                : Number(levelString),
                    }))
                    .sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1))
                    .map(({ value, label }) => (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    ))}
            </TextField>
            <TextField
                select
                variant="outlined"
                label="Scholarship"
                SelectProps={{
                    value: scholarshipId,
                    fullWidth: true,
                    disabled: formState.isSubmitting || !level || !scholarshipsForSelectedLevel.length,
                    onChange: handleScholarshipChange,
                }}
            >
                {scholarshipsForSelectedLevel.map(option => (
                    <MenuItem value={option.id} key={option.id}>
                        {optionLabel(t, option)}
                    </MenuItem>
                ))}
            </TextField>
            <IconButton
                color="error"
                className="remove-scholarship"
                data-testid="remove-scholarship"
                onClick={internalHandleRemoveScholarship}
            >
                <Delete />
            </IconButton>
        </>
    );
};
export default ChangeScholarshipSelect;
