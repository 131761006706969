import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { getTuitionContractForProgramInclusion, type BaseUser } from 'Users';
import { type ProgramInclusion } from 'ProgramInclusion';
import { type AdmissionOffer } from 'AdmissionOffer';
import { type PaymentSituation } from 'PaymentSituation';
import { ScholarshipSection } from '../../HelperComponents/ScholarshipSection';
import ChangeCohortSelect from '../../HelperComponents/ChangeCohortSelect';
import { InclusionActionType, UserManagementActionNamespace, type FormFieldProps } from '../UserManagementAction.types';
import AbstractUserManagementAction from '../AbstractUserManagementAction';
import { useCohorts, useGetUserInAdmin, useOfferableScholarships } from '../../redux';
import { ChangeSectionSelect, ProgramPaymentCard, PaymentReconciliationCard } from '../../HelperComponents';
import { useScholarshipSelection } from '../../utils/useScholarshipSelection';
import TuitionPlanSelect from '../../HelperComponents/TuitionPlanSelect';
import { useSetDefaultTuitionPlanId, useTuitionPlans } from '../AdmissionOffer/RegisterStudentHelpers';
import usePaymentSituation from '../../utils/usePaymentSituation';
import { type ChangeProgramAttrs, type FormFields, type ExecuteActionParams } from './ChangeProgram.types';

import './ChangeProgram.scss';

class ChangeProgram extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeProgram;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    canBackOutOfRegistration: boolean;
    availableTargetCohortIds: string[];
    selectablePaymentSituations: PaymentSituation[];

    constructor(attrs: ChangeProgramAttrs) {
        super(attrs);
        this.canBackOutOfRegistration = attrs.canBackOutOfRegistration;
        this.availableTargetCohortIds = attrs.availableTargetCohortIds;
        this.selectablePaymentSituations = attrs.selectablePaymentSituations;
    }

    get description() {
        return (
            <>
                <p>Use to change a student&apos;s program. Payments will be carried over.</p>
                {this.canBackOutOfRegistration ? (
                    <p>
                        The user will be backing out of registration in their existing program and will receive a new
                        program application, admission offer, and program inclusion for the new program.
                    </p>
                ) : (
                    <p>
                        Since the cohort the user registered for has already started, they cannot be backed out of
                        registration. They will be withdrawn from their existing program with the reason marked as
                        &quot;Transferred to another program.&quot; They will receive a new program application,
                        admission offer, and program inclusion for the new program.
                    </p>
                )}
            </>
        );
    }

    static formValidationSchema = Yup.object().shape({
        cohortId: Yup.string().required(),
        cohortSectionId: Yup.string().required(),
        scholarshipIds: Yup.array().of(Yup.string().required()).required(),
        tuitionPlanId: Yup.string().required(),
    });

    formatFormValues({ cohortId, cohortSectionId, scholarshipIds, tuitionPlanId }: FormFields): ExecuteActionParams {
        return {
            cohortId,
            cohortSectionId,
            scholarshipIds: scholarshipIds.filter(Boolean),
            tuitionPlanId,
        };
    }

    // eslint-disable-next-line max-lines-per-function
    static FormFields = ({
        userId,
        action: { availableTargetCohortIds, selectablePaymentSituations },
        record,
    }: FormFieldProps<ProgramInclusion, ChangeProgram>) => {
        const {
            includedCohortSectionId,
            cohortId: oldCohortId,
            cumulativeScholarship: oldCumulativeScholarship,
            tuitionPlanId: oldTuitionPlanId,
            admissionOfferId,
        } = record as ProgramInclusion;

        const { cohortsById } = useCohorts();
        const { user } = useGetUserInAdmin(userId);
        const { watch, setFieldValue, formState } = useFormContext<FormFields>();
        const [hasHandledCohortChange, setHasHandledCohortChange] = useState(false);
        const [cohortId, cohortSectionId, tuitionPlanId, scholarshipIds] = watch([
            'cohortId',
            'cohortSectionId',
            'tuitionPlanId',
            'scholarshipIds',
        ]);

        const admissionOffer = useMemo(
            () => user?.admissionOffers.find((ao: AdmissionOffer) => ao.id === admissionOfferId),
            [user, admissionOfferId],
        );

        const sectionOffer = useMemo(
            () => admissionOffer?.cohortSectionOffers.find(({ cohortSectionId: id }) => id === includedCohortSectionId),
            [admissionOffer, includedCohortSectionId],
        );

        if (scholarshipIds === undefined) {
            setFieldValue('scholarshipIds', sectionOffer?.offeredScholarshipIds || []);
        }

        const oldCohort = useMemo(() => cohortsById[oldCohortId], [cohortsById, oldCohortId]);
        const newCohort = useMemo(() => cohortsById[cohortId], [cohortsById, cohortId]);
        const sections = useMemo(() => newCohort?.cohortSections || [], [newCohort]);

        const isIncludedSectionInSelectedCohort = useMemo(
            () => sections.find(({ id }) => id === includedCohortSectionId),
            [sections, includedCohortSectionId],
        );

        const { availableScholarships } = useOfferableScholarships(newCohort?.availableScholarshipIds);
        const { availableScholarships: oldAvailableScholarships } = useOfferableScholarships(
            oldCohort?.availableScholarshipIds,
        );

        const originalPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            oldTuitionPlanId,
            oldCumulativeScholarship,
        );

        const { cumulativeScholarship: newCumulativeScholarship } = useScholarshipSelection({
            cohortForAvailableScholarships: newCohort,
            initialScholarshipIds: scholarshipIds?.filter(Boolean) as string[],
        });

        const newPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            tuitionPlanId,
            newCumulativeScholarship,
        );

        const tuitionContract = getTuitionContractForProgramInclusion(user as BaseUser, record as ProgramInclusion);

        const oldFrequency = useMemo(() => tuitionContract?.tuitionPlan.frequency, [tuitionContract]);

        const tuitionPlans = useTuitionPlans(
            selectablePaymentSituations,
            newCumulativeScholarship,
            newCohort?.availableTuitionPlanIds,
        );

        useSetDefaultTuitionPlanId(tuitionPlans, tuitionPlanId);

        useEffect(() => {
            if (scholarshipIds === undefined && sectionOffer) {
                setFieldValue('scholarshipIds', sectionOffer.offeredScholarshipIds);
            }
        }, [scholarshipIds, sectionOffer, setFieldValue]);

        // This effect is used to map the original scholarships to their equivalent in the new cohort
        // based on the level and label and auto-select them in the scholarship select. This should only
        // run once after the cohort dropdown has been changed.
        useEffect(() => {
            if (!cohortId || !oldCohortId || hasHandledCohortChange || !newCohort || !oldCohort) return;

            const oldScholarshipIds = sectionOffer?.offeredScholarshipIds || [];
            if (!oldScholarshipIds.length) {
                setHasHandledCohortChange(true);
                return;
            }

            const mappedScholarshipIds = oldScholarshipIds
                .map(originalId => {
                    const originalScholarship = oldAvailableScholarships.find(s => s.id === originalId);
                    if (!originalScholarship) return undefined;

                    return availableScholarships.find(
                        s => s.level === originalScholarship.level && s.label === originalScholarship.label,
                    )?.id;
                })
                .filter(Boolean) as string[];

            setFieldValue('scholarshipIds', mappedScholarshipIds);
            setHasHandledCohortChange(true);
        }, [
            cohortId,
            oldCohortId,
            hasHandledCohortChange,
            newCohort,
            oldCohort,
            sectionOffer,
            setFieldValue,
            availableScholarships,
            oldAvailableScholarships,
        ]);

        // Reset tracking when cohort changes
        useEffect(() => {
            setHasHandledCohortChange(false);
        }, [cohortId]);

        useEffect(() => {
            if (isIncludedSectionInSelectedCohort) {
                setFieldValue('cohortSectionId', isIncludedSectionInSelectedCohort.id);
            }
        }, [setFieldValue, isIncludedSectionInSelectedCohort]);

        useEffect(() => {
            if (!oldFrequency) {
                return;
            }

            const tuitionPlan = tuitionPlans.find(tp => tp.frequency === oldFrequency);
            if (tuitionPlan) {
                setFieldValue('tuitionPlanId', tuitionPlan.id);
            }
        }, [oldFrequency, setFieldValue, tuitionPlans]);

        const showWarning = !isIncludedSectionInSelectedCohort && cohortId;

        return (
            <>
                <ChangeCohortSelect availableTargetCohortIds={availableTargetCohortIds} />
                <ChangeSectionSelect name="cohortSectionId" sections={sections} />
                {showWarning && (
                    <div className="change-program-section-warning">
                        The section this user is currently included in is not available for this cohort. Pick a new
                        section.
                    </div>
                )}
                {newCohort && cohortSectionId && (
                    // FIXME: DRY with similar UI in EditSectionOffers, https://trello.com/c/VXtmE4yy
                    <>
                        <div className="tuition-plan-wrapper">
                            <TuitionPlanSelect
                                name="tuitionPlanId"
                                tuitionPlans={tuitionPlans}
                                disabled={
                                    formState.isSubmitting || scholarshipIds.some(s => !s) || tuitionPlans.length < 1
                                }
                            />
                        </div>

                        <ScholarshipSection
                            cohortForAvailableScholarships={newCohort}
                            initialScholarshipIds={scholarshipIds?.filter(Boolean) || []}
                            disabled={formState.isSubmitting}
                        />
                    </>
                )}
                {originalPaymentSituation && admissionOffer && (
                    <div className="payment-information">
                        <ProgramPaymentCard
                            title="Original Program"
                            paymentSituation={originalPaymentSituation}
                            cohortId={admissionOffer.cohortId}
                            cohortLabel="Offer Cohort"
                        />
                        {newPaymentSituation && (
                            <ProgramPaymentCard
                                title="New Program"
                                paymentSituation={newPaymentSituation}
                                showDiffFromPaymentSituation={originalPaymentSituation}
                                cohortId={cohortId}
                            />
                        )}
                    </div>
                )}
                {newPaymentSituation && tuitionContract && (
                    <PaymentReconciliationCard
                        newPaymentSituation={newPaymentSituation}
                        tuitionContract={tuitionContract}
                    />
                )}
            </>
        );
    };
}

export default ChangeProgram;
