import { type PaymentSituation } from 'PaymentSituation';
import { ProgramPaymentCard } from './ProgramPaymentCard';

interface PaymentInformationSectionProps {
    paymentSituations: {
        original: PaymentSituation;
        new: PaymentSituation | null | undefined;
    };
    titles?: {
        original?: string;
        new?: string;
    };
    showNewPayment?: boolean;
    cohortId: string;
    cohortLabel?: string;
}

export const PaymentInformationSection = ({
    paymentSituations: { original: originalPaymentSituation, new: newPaymentSituation },
    titles,
    showNewPayment = false,
    cohortId,
    cohortLabel,
}: PaymentInformationSectionProps) => {
    const originalTitle = titles?.original;
    const newTitle = titles?.new;

    return (
        <div className="payment-information">
            <ProgramPaymentCard
                title={originalTitle || 'Original Payment Information'}
                paymentSituation={originalPaymentSituation}
                cohortId={cohortId}
                cohortLabel={cohortLabel}
            />
            {showNewPayment && newPaymentSituation && (
                <ProgramPaymentCard
                    title={newTitle || 'New Payment Information'}
                    paymentSituation={newPaymentSituation}
                    showDiffFromPaymentSituation={originalPaymentSituation}
                    cohortId={cohortId}
                    cohortLabel={cohortLabel}
                />
            )}
        </div>
    );
};
