import { useMemo } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import { getTuitionContractForProgramInclusion, type BaseUser } from 'Users';
import { type ProgramInclusion } from 'ProgramInclusion';
import { type AdmissionOffer } from 'AdmissionOffer';
import { type PaymentSituation } from 'PaymentSituation';
import { useScholarshipSelection } from '../../utils/useScholarshipSelection';
import { useCohorts, useGetUserInAdmin } from '../../redux';
import AbstractUserManagementAction from '../AbstractUserManagementAction';
import usePaymentSituation from '../../utils/usePaymentSituation';
import { ScholarshipSection } from '../../HelperComponents/ScholarshipSection';
import { PaymentInformationSection } from '../../HelperComponents/PaymentInformationSection';
import { PaymentReconciliationCard } from '../../HelperComponents';
import {
    InclusionActionType,
    UserManagementActionNamespace,
    type FormFieldProps,
    type UserManagementActionAttrs,
} from '../UserManagementAction.types';

import './ChangeProgram.scss';

type FormFields = {
    scholarshipIds: (string | undefined)[];
};

interface ChangeScholarshipAfterRegistrationAttrs extends UserManagementActionAttrs {
    selectablePaymentSituations: PaymentSituation[];
}

class ChangeScholarshipAfterRegistration extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeScholarshipAfterRegistration;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    selectablePaymentSituations: PaymentSituation[];

    constructor(attrs: ChangeScholarshipAfterRegistrationAttrs) {
        super(attrs);
        this.selectablePaymentSituations = attrs.selectablePaymentSituations;
    }

    get description() {
        return (
            <p>
                Use in rare circumstances when a student has registered but is approved for a change in scholarship. Can
                only be done prior to 30 days after cohort start and if the student has made, at most, one payment.
            </p>
        );
    }

    static formValidationSchema = Yup.object().shape({
        scholarshipIds: Yup.array().of(Yup.string().required()).min(1),
    });

    formatFormValues({ scholarshipIds }: FormFields) {
        return {
            scholarshipIds,
        };
    }

    // eslint-disable-next-line max-lines-per-function
    static FormFields = ({
        userId,
        action: { selectablePaymentSituations },
        record,
    }: FormFieldProps<ProgramInclusion, ChangeScholarshipAfterRegistration>) => {
        const {
            cumulativeScholarship: currentCumulativeScholarship,
            scholarshipIds: currentScholarshipIds,
            tuitionPlanId: currentTuitionPlanId,
            admissionOfferId,
        } = record as ProgramInclusion;

        const { user } = useGetUserInAdmin(userId);
        const { formState } = useFormContext();
        const admissionOffer = useMemo(
            () => user?.admissionOffers.find((ao: AdmissionOffer) => ao.id === admissionOfferId),
            [user, admissionOfferId],
        );

        const tuitionContract = getTuitionContractForProgramInclusion(user as BaseUser, record);

        const { cohortsById } = useCohorts();
        const offerCohort = cohortsById[admissionOffer?.cohortId || ''];

        const { cumulativeScholarship: newCumulativeScholarship, hasSelectedScholarships } = useScholarshipSelection({
            cohortForAvailableScholarships: offerCohort,
            initialScholarshipIds: currentScholarshipIds,
        });

        const originalPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            currentTuitionPlanId,
            currentCumulativeScholarship,
        );

        const newPaymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            currentTuitionPlanId,
            newCumulativeScholarship,
        );

        return (
            <>
                <ScholarshipSection
                    cohortForAvailableScholarships={offerCohort}
                    initialScholarshipIds={currentScholarshipIds}
                    disabled={formState.isSubmitting}
                />

                {originalPaymentSituation && admissionOffer && (
                    <PaymentInformationSection
                        paymentSituations={{
                            original: originalPaymentSituation,
                            new: newPaymentSituation,
                        }}
                        titles={{
                            original: 'Original Payment',
                            new: 'New Payment',
                        }}
                        showNewPayment={hasSelectedScholarships}
                        cohortId={offerCohort?.id || ''}
                        cohortLabel="Offer Cohort"
                    />
                )}

                {newPaymentSituation && tuitionContract && hasSelectedScholarships && (
                    <PaymentReconciliationCard
                        newPaymentSituation={newPaymentSituation}
                        tuitionContract={tuitionContract}
                    />
                )}
            </>
        );
    };
}

export default ChangeScholarshipAfterRegistration;
